import { values } from 'ramda';
import { AnyAction } from 'redux';
import { ActionsObservable, StateObservable, combineEpics } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { initAccessControlListener } from 'lib/access-control/epics/listener';
import { authAnalyticsEpic } from 'lib/auth/analytics';
import { budgetAnalyticsEpic } from 'lib/budget/analytics';
import { initBudgetListener } from 'lib/budget/listener';
import {
  fetchTasksInitialEpic,
  initChecklistAnalyticsEpic,
  initChecklistListener,
} from 'lib/checklist/listener';
import { IConfigureDeps } from 'lib/configure-deps';
import { badgesConnectedActionsEpic, connectedActionsEpic } from 'lib/connected-actions';
import { triggerSupplierContentTranslationsEpic } from 'lib/content-translations/epics';
import { datepickerAnalyticsEpics } from 'lib/datepicker/analytics';
import { closeEnquiryOnEnquiryLimitEpic } from 'lib/enquiries/actions';
import {
  enquiriesServerSideTrackEpic,
  enquiryFormToggleAnalyticsEpic,
} from 'lib/enquiries/analytics/epics';
import * as filesEpics from 'lib/files/epics';
import { initGuestlistListener } from 'lib/guestlist/listener';
import { getAndUpdateViewerCountryEpic } from 'lib/i18n/epics';
import { newOnboardingEpics } from 'lib/onboarding-new/epics';
import { initScrapbookListener } from 'lib/scrapbook/listener';
import {
  changedSearchLocationAnalyticsDebouncedEpic,
  fetchSearchSuggestionsAnalyticsEpic,
  sendAnalyticsOnFetchSearchSuccessEpic,
} from 'lib/search/analytics';
import * as searchEpics from 'lib/search/epics';
import { changeWeddingCountryEpic, deleteMicrositeEpic } from 'lib/settings/epics';
import { shortlistAnalyticsEpic } from 'lib/shortlist/analytics';
import { initShortlistListener } from 'lib/shortlist/listener';
import { supplierAnalyticsEpic } from 'lib/supplier/analytics';
import {
  initActiveTodayTaskDefinition,
  initUserTodayTaskListener,
  initWeddingTodayTaskListener,
} from 'lib/task/listener';
import { trackCordovaEpic } from 'lib/track-utils/epics/';
import { IApplicationState, IEpic } from 'lib/types';
import { initUserListener } from 'lib/users/listener';
import { profileAnalyticsListenerEpic } from 'lib/weddings/analytics';
import { initWeddingListener } from 'lib/weddings/listener';
import * as accessControlEpics from './access-control/epics';
import {
  fetchGroups,
  globalListenersInitEpic,
  globalListenersStopEpic,
  qaHelpersEpic,
  routingEpic,
  windowResizeAndroidScrollToInputEpic,
  windowResizeEpic,
} from './app/epics';
import * as authEpics from './auth/epics';
import { bbCommonEpic } from './bbcommon/actions';
import {
  closeTaskDrawerEpic,
  initialiseChecklistEpic,
  updateGroupEpic,
  updateTasksEpic,
  updateTasksTotalsOnWeddingEpic,
} from './checklist/epics';
import {
  animeDatePickedEpic,
  changeDatepickerDateEpics,
  changeTabEpics,
  closeDatepickerOnProfileUpdateEpic,
  nextTabEpics,
} from './datepicker/epics';
import {
  changeEnquiryContextEpic,
  confirmEnquiryFormEpic,
  saveEnquiryFieldsToDatabaseEpic,
  sendEnquiryEpic,
} from './enquiries/actions';
import {
  fetchEnquiryConfirmationSupplierEpic,
  fetchEnquiryCountEpic,
  fetchEnquiryDatesEpic,
} from './enquiries/epics';
import {
  appUrlOpenEpic,
  appsFlyerPBTracking,
  backButtonEpic,
  getAppBuildInfoEpic,
  hideSplashScreenEpic,
  mobileNativeKeyboardEpic,
  pushNotificationsSaveDeviceTokenEpics,
  registerPushNotificationEpic,
  registerPushNotificationErrorEpic,
  registerPushNotificationsListenerEpic,
  registerTalkJsDeviceEpic,
  removePushNotificationTokenOnSignOutEpic,
  requestPushNotificationPermissionsOptInEpic,
  scheduleRegisterTalkJsDeviceEpic,
  showAppRatePopupEpic,
  showPushNotificationOptInPopupEpic,
} from './mobile-app/epics';
import * as realWeddingsEpics from './real-weddings/epics';
import {
  closeAddSupplierModalsEpic,
  fetchShortlistedSuppliersEpic,
  fetchSupplierCollaborationEpic,
  shortlistBookSupplierEpic,
  shortlistBookSupplierUpdateLocationEpic,
  shortlistUnbookSupplierEpic,
  toggleSupplierCollaborationEpic,
  unbookAllVenuesEpic,
  updateShortlistedSupplierEpic,
  viewedShortlistEpic,
} from './shortlist/epics';
import {
  createCustomSupplierEpic,
  customSupplierSuccessEpic,
  validateFormEpics,
} from './shortlist/epics-custom-supplier';
import { registerSupplierCardInView } from './supplier/epics';
import uiEpics from './ui/epics';
import { debouncedGoBackHomeEpic } from './ui/epics/debounced-go-back-home-epic';
import * as userEpics from './users/epics';
import {
  confirmOrDenySupplierInterestEpic,
  onVenueBookedConfirmedShowDiscoverPhotographers,
  onVenueBookedSetDiscoverPhotographers,
  openSupplierInterestConfirmationEpic,
  openSupplierMultiEnquiryPopupOnEnquiryUpdate,
  openVenueConfirmationPopup,
  openVenuePopupAfterTimePeriod,
  openVenuePopupFromUrlParam,
  openVenuePopupOnBudgetChange,
  openVenuePopupOnChecklistMainTaskChange,
  openVenuePopupOnChecklistSubtaskChange,
  openVenuePopupOnGuestlistExport,
  openVenuePopupOnMarkedTwoNonVenueAsBooked,
  recommendedSuppliers,
  venueRecommendedSuppliers,
} from './venue-confirmation/epics';
import {
  fetchCountryBoundsEpic,
  initInboxListenerEpic,
  updateBookingsVenueEpic,
  updateWeddingFieldEpic,
  updateWeddingPreferencesEpic,
  weddingProfileSaveDateEpics,
} from './weddings/epics';

const globalEpics: IEpic[] = values({
  // app
  trackCordovaEpic,
  windowResizeEpic,
  windowResizeAndroidScrollToInputEpic,
  routingEpic,
  fetchGroups,
  globalListenersInitEpic,
  globalListenersStopEpic,
  // i18n
  getAndUpdateViewerCountryEpic,
  // mobileapp
  pushNotificationsSaveDeviceTokenEpics,
  scheduleRegisterTalkJsDeviceEpic,
  registerTalkJsDeviceEpic,
  showAppRatePopupEpic,
  mobileNativeKeyboardEpic,
  hideSplashScreenEpic,
  showPushNotificationOptInPopupEpic,
  registerPushNotificationEpic,
  registerPushNotificationErrorEpic,
  registerPushNotificationsListenerEpic,
  requestPushNotificationPermissionsOptInEpic,
  removePushNotificationTokenOnSignOutEpic,
  backButtonEpic,
  appUrlOpenEpic,
  appsFlyerPBTracking,
  getAppBuildInfoEpic,
  // searchScrollEpic,
  qaHelpersEpic,
  // auth
  ...authEpics,
  // ACL
  ...accessControlEpics,
  // users
  ...userEpics,
  shortlistBookSupplierUpdateLocationEpic,
  initUserListener,
  // // enquiries
  closeEnquiryOnEnquiryLimitEpic,
  fetchEnquiryConfirmationSupplierEpic,
  fetchEnquiryDatesEpic,
  fetchEnquiryCountEpic,
  sendEnquiryEpic,
  saveEnquiryFieldsToDatabaseEpic,
  // showEnquiryConfirmationScreenEpic,
  changeEnquiryContextEpic,
  confirmEnquiryFormEpic,
  // enquiries analytics
  enquiryFormToggleAnalyticsEpic,
  enquiriesServerSideTrackEpic,
  // // search
  ...searchEpics,
  // guestlist
  initGuestlistListener,
  // shortlist
  initShortlistListener,
  createCustomSupplierEpic,
  customSupplierSuccessEpic,
  validateFormEpics,
  shortlistBookSupplierEpic,
  shortlistUnbookSupplierEpic,
  viewedShortlistEpic,
  unbookAllVenuesEpic,
  closeAddSupplierModalsEpic,
  fetchShortlistedSuppliersEpic,
  toggleSupplierCollaborationEpic,
  fetchSupplierCollaborationEpic,
  updateShortlistedSupplierEpic,
  // checklist
  fetchTasksInitialEpic,
  initChecklistListener,
  initChecklistAnalyticsEpic,
  updateGroupEpic,
  updateTasksEpic,
  closeTaskDrawerEpic,
  updateTasksTotalsOnWeddingEpic,
  initBudgetListener,
  debouncedGoBackHomeEpic,
  ...uiEpics,
  // datepicker
  changeTabEpics,
  nextTabEpics,
  changeDatepickerDateEpics,
  animeDatePickedEpic,
  // validateCurrentTabEpic,
  closeDatepickerOnProfileUpdateEpic,
  // profile
  // weddingProfileListenerEpic,
  updateWeddingFieldEpic,
  updateWeddingPreferencesEpic,
  updateBookingsVenueEpic,
  weddingProfileSaveDateEpics,
  fetchCountryBoundsEpic,
  initInboxListenerEpic,
  // analytics
  profileAnalyticsListenerEpic,
  authAnalyticsEpic,
  shortlistAnalyticsEpic,
  supplierAnalyticsEpic,
  datepickerAnalyticsEpics,
  budgetAnalyticsEpic,
  sendAnalyticsOnFetchSearchSuccessEpic,
  changedSearchLocationAnalyticsDebouncedEpic,
  fetchSearchSuggestionsAnalyticsEpic,
  registerSupplierCardInView,
  bbCommonEpic,
  // Settings
  deleteMicrositeEpic,
  changeWeddingCountryEpic,
  // Scrapbook
  initScrapbookListener,
  initWeddingListener,
  initAccessControlListener,
  // Connected actions
  connectedActionsEpic,
  badgesConnectedActionsEpic,
  // venuerexModalEpic,
  // countToVenuerexModalEpic,
  //files
  ...filesEpics,
  //  Venue Confirmation popup
  openVenuePopupOnBudgetChange,
  openVenuePopupOnChecklistMainTaskChange,
  openVenuePopupOnChecklistSubtaskChange,
  openVenuePopupOnGuestlistExport,
  openVenuePopupOnMarkedTwoNonVenueAsBooked,
  onVenueBookedSetDiscoverPhotographers,
  openSupplierInterestConfirmationEpic,
  confirmOrDenySupplierInterestEpic,
  onVenueBookedConfirmedShowDiscoverPhotographers,
  openVenuePopupAfterTimePeriod,
  openVenuePopupFromUrlParam,
  openSupplierMultiEnquiryPopupOnEnquiryUpdate,
  openVenueConfirmationPopup,
  venueRecommendedSuppliers,
  recommendedSuppliers,
  // Real Weddings
  ...realWeddingsEpics,
  // New onboarding
  newOnboardingEpics,
  //task
  initWeddingTodayTaskListener,
  initUserTodayTaskListener,
  initActiveTodayTaskDefinition,
  //checklist
  initialiseChecklistEpic,
  // content translations
  triggerSupplierContentTranslationsEpic,
}).filter((item): item is IEpic => typeof item === 'function');

let epic$: BehaviorSubject<IEpic> | null;

const configureEpics =
  (deps: IConfigureDeps) =>
  (action$: ActionsObservable<AnyAction>, state$: StateObservable<IApplicationState>) => {
    // https://github.com/redux-observable/redux-observable/blob/master/docs/recipes/AddingNewEpicsAsynchronously.md
    // add BehaviorSubject for lazy load of epics
    if (!epic$) {
      epic$ = new BehaviorSubject(combineEpics(...globalEpics));
    }
    // @ts-ignore FIXME
    return epic$.pipe(mergeMap((epic) => epic(action$, { ...deps, state$ })));
  };

export const getEpic = () => epic$;

export default configureEpics;
